<!--头部-->
<template>
  <div class="topBox">
    <img src="@/assets/img/screen-header.png" width="100%"/>
    <div class="content">
      <span class="date">{{ date[0] }}</span>
      <router-link class="title" to="/">{{ title }}</router-link>
      <el-dropdown class="date">
        <span>{{ date[1] }}<i class="el-icon-arrow-down el-icon--right"/></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-user">个人中心</el-dropdown-item>
          <el-dropdown-item icon="el-icon-refresh-left">
            <router-link to="/">返回首页</router-link>
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" divided>
            <span @click="logOut">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment'

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: 'xx市数字乡村'
    }
  },
  data() {
    return {
      date: ''
    }
  },
  created() {
    // 获取时间
    setInterval(() => {
      this.date = moment().format('YYYY-MM-DD HH:mm:ss').split(' ')
    }, 1000)
  },
  methods: {
    // 登出
    logOut() {
      this.$confirm('确认退出登录吗？', {
        showClose: false,
        type: 'warning',
        customClass: 'logOut'
      }).then(_ => {
        localStorage.removeItem('token')
        this.$router.push('/login')
      }).catch(_ => {})
    }
  }
}
</script>

<style scoped lang="scss">
.topBox {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;

  .content {
    position: absolute;
    width: 100%;
    height: 45%;
    top: 0;
    right: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;

    .title {
      margin: 4vh auto 0;
      font-family: '黑体';
      font-size: 40px;
      letter-spacing: 6px;
      background: linear-gradient(to top, #ffffff 50%, #1791e5);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .date {
      font-size: 16px;
      color: #fff;
      width: 150px;

      &.el-dropdown {
        text-align: right;

        .el-dropdown-selfdefine {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.logOut {
  vertical-align: top;
  margin-top: 20vh;
}
</style>
