import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 公共参数
import global from '@/config/globalSet'
Vue.prototype.$global = global

// 自定义组件
import '@/components'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
