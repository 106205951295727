// 全局参数配置
export default {
    remind: '此功能暂未开放，请联系博主！',
    // ----------------------------echarts相关----------------------------
    echarts: {
        primaryColor: '#9eccef', // 主色
        gradientShadow: [['#00ffff', 'rgba(0, 0, 0, 0)'], ['#ffcd46', 'rgba(0, 0, 0, 0)']], // 渐变阴影
        pillarShadow: [['#33a0ff', '#276399'], ['#00ffff', '#088590'], ['#aeeb68', '#567d4d'], ['#15ffb1', '#12866f'], ['#ffcd46', '#7c6f3b']], // 柱状图渐变
        pieColor: ['#09C3E0', '#FFCC00', '#EB5472', '#f6b980', '#aeeb68', '#9451e0'] // 饼图颜色
    }
}
