// 地图
export default {
    namespaced: true,
    state: {
        map: null,
        mapKey: '8eb14d927a3237217ea74804700ee8ba',
        center: [120.1182, 28.9416],
        zoom: 11.2,
        yongKangLayer: null //数据图层
    },
    mutations: {
        setMap(state, map) {
            state.map = map
        },
        setYongKangLayer(state, layer) {
            state.yongKangLayer = layer
        }
    }
}
