<!--自定义弹窗-->
<template>
  <div>
    <!--窗体-->
    <div class="myDialog" :style="custom">
      <p class="header">{{ title }}
        <img src="@/assets/img/close.png" @click="$emit('close')"/>
      </p>
      <slot/>
    </div>
    <!--蒙层-->
    <span v-if="mask" class="mask" @click="$emit('close')"/>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: {},
    mask: {
      default: true
    },
    width: {
      default: '50vw'
    },
    left: {},
    top: {
      default: '15vh'
    }
  },
  computed: {
    // 弹窗样式
    custom() {
      return {
        width: this.width,
        left: this.left || `calc((100vw - ${this.width})/2)`,
        top: this.top
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.myDialog) {
  position: fixed;
  z-index: 12;
  background-image: url('~@/assets/img/dialog-bj.png');
  background-size: 100% 100%;
  padding: 0 20px 10px;

  // 标题
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed rgba(158, 208, 239, 0.2);
    line-height: 50px;
    margin-bottom: 15px;

    img {
      height: 18px;
      cursor: pointer;
    }
  }

  // 搜索
  .search {
    display: flex;
    white-space: nowrap;
    margin-bottom: 10px;
    font-size: 14px;

    .item {
      color: #9ECCEF;
      margin-right: 30px;

      input {
        background: url('~@/assets/img/icon8.png') no-repeat;
        background-size: 100% 100%;
        border: none;
        margin-left: 5px;
        width: 140px;
        color: #9ECCEF;
        border-radius: 0;

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    button {
      background: url('~@/assets/img/but.png') no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #fff;
      border-radius: 0;
      margin-left: auto;
    }
  }

  // 指标
  .target {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    p {
      width: 49.5%;
      background: url("~@/assets/img/icon2.png") no-repeat;
      background-size: 100% 100%;
      line-height: 40px;
      height: 40px;
      text-align: center;

      span {
        color: #ffce47;

        i {
          font-family: LEDFont;
          font-size: 24px;
          margin-right: 3px;
        }
      }
    }
  }

  // table
  .el-table {
    background: none;
    border-top: 1px solid rgba(69, 143, 252, 0.7);
    margin-bottom: 15px;

    &:before {
      background: rgba(69, 143, 252, 0.7);
    }

    tr {
      background: none;

      th {
        color: #fff;
      }

      td {
        color: #9ECCEF;
      }

      th, td {
        border-color: rgba(69, 143, 252, 0.4);
      }

      .cell {
        text-align: center;
      }
    }

    th,
    tr:nth-child(2n) td,
    tr:hover td {
      background: rgba(45, 126, 231, 0.15);
    }
  }

  // 分页
  .el-pagination {
    color: #9ECCEF;
    text-align: center;

    span {
      color: inherit;
    }

    input, li.active {
      background: none;
      color: inherit;
      border: 1px solid rgba(69, 143, 252, 0.7);
      border-radius: 4px;
    }

    button, li {
      background: none;
      color: inherit;
    }
  }
}

// 蒙层
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
}
</style>
