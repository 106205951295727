import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//白名单
let whiteList = ['/login']

var routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
        title: '登录'
    }
}, {
    path: '/',
    name: 'menus',
    component: () => import('@/views/menus'),
    meta: {
        title: '导航'
    }
}, {
    path: '/screen',
    name: 'screen',
    component: () => import('@/views/screen'),
    redirect: '/screen/cockpit1',
    children: [{
        path: '/screen/cockpit1',
        name: 'cockpit1',
        component: () => import('@/views/screen/cockpit1'),
        meta: {
            title: '首页'
        }
    }, {
        path: '/screen/cockpit2',
        name: 'cockpit2',
        component: () => import('@/views/screen/cockpit2/index.vue'),
        meta: {
            title: '永农引商'
        }
    }, {
        path: '/screen/cockpit3',
        name: 'cockpit3',
        component: () => import('@/views/screen/cockpit3/index.vue'),
        meta: {
            title: '房险在线'
        }
    }, {
        path: '/screen/cockpit4',
        name: 'cockpit4',
        component: () => import('@/views/screen/cockpit4/index.vue'),
        meta: {
            title: '绿农碳客'
        }
    }]
}, {
    path: '/database',
    name: 'database',
    component: () => import('@/views/database'),
    meta: {
        title: '三农数据库'
    }
}]

const router = new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (!localStorage.token) {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    } else {
        if (to.path === '/login') {
            next(from.path)
        } else {
            next()
        }
    }
})

export default router

