<!--无缝滚动-->
<template>
  <vue-seamless-scroll v-bind="$attrs" :class-option="{ step: 0.5 }" class="warp">
    <slot/>
  </vue-seamless-scroll>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'SeamlessScroll',
  components: {vueSeamlessScroll}
}
</script>

<style scoped lang="scss">
.warp {
  margin: 8px;
  overflow: hidden;

  :deep(.scrollItem) {
    margin-bottom: 10px;
    background: url("~@/assets/img/bj2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 10px;

    img {
      height: 20px;
    }

    .el-divider {
      opacity: 0.3;
      height: 25px;
    }

    .tag {
      margin-left: auto;
      padding-left: 8px;
      white-space: nowrap;
      line-height: 45px;
      color: #99ddff;
    }

    .info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
