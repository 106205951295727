import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import olMap from './modules/olMap' //地图

export default new Vuex.Store({
    modules: {
        olMap
    }
})
